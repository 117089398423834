\<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="인터락번호"
            name="interLockNo"
            v-model="searchParam.interLockNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="대상설비"
            name="facilityName"
            v-model="searchParam.facilityName">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관련공정 -->
          <c-process
            label="LBL0001705"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관리부서 -->
          <c-dept
            type="search"
            label="LBL0001721"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 인터락 작동조건 및 가동중지 범위 목록 -->
        <c-table
          ref="interlock"
          title="LBL0010465"
          tableId="interlock"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          rowKey="psiInterLockId"
          :columnSetting="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 등록 -->
              <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="equipmentPopup" />
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'inter-lock',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'interLockNo',
            field: 'interLockNo',
            // 인터락 번호
            label: 'LBL0010466',
            align: 'center',
            style: 'width:120px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'facilityName',
            field: 'facilityName',
            // 대상설비
            label: 'LBL0010467',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            // 설정값
            label: 'LBL0010468',
            style: 'width:80px',
            align: 'center',
            child: [
              {
                name: 'temperature',
                field: 'temperature',
                // 온도(℃)
                label: 'LBL0010396',
                align: 'center',
                style: 'width:100px',
                sortable: true,
              },
              {
                name: 'pressure',
                field: 'pressure',
                // 압력(Mpa)
                label: 'LBL0010393',
                align: 'center',
                style: 'width:100px',
                sortable: true,
              },
              {
                name: 'liquidLevel',
                field: 'liquidLevel',
                // 액위(m)
                label: 'LBL0010469',
                align: 'center',
                style: 'width:100px',
                sortable: true,
              },
              {
                name: 'etc',
                field: 'etc',
                // 기타
                label: 'LBL0001222',
                align: 'center',
                style: 'width:100px',
                sortable: true,
              },
            ]
          },
          {
            name: 'detectorNum',
            field: 'detectorNum',
            // 감지기번호
            label: 'LBL0010470',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'lastOperationEquipment',
            field: 'lastOperationEquipment',
            // 최종작동설비
            label: 'LBL0010471',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'operationStopRange',
            field: 'operationStopRange',
            // 가동중지범위
            label: 'LBL0010472',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'checkCycle',
            field: 'checkCycle',
            // 점검주기
            label: 'LBL0010473',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            // 비고
            label: 'LBLREMARK',
            align: 'center',
            style: 'width:200px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        interLockNo: '',
        facilityName: '',
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.pcd.interlock.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.equipmentPopup(row);
    },
    equipmentPopup(result) {
      this.popupOptions.target = () => import(`${"./interlockOperationDetail.vue"}`);
      this.popupOptions.title = 'LBL0010474'; // 인터락 작동조건및 가동중지 범위 상세
      this.popupOptions.param = {
        psiInterLockId: result ? result.psiInterLockId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
